<template>
  <div style="padding-top: 20px;">
    <el-row type="flex" :gutter="20">
      <el-col :span="5">
        <div class="param-title">{{ parameterType }}[{{ index }}]</div>
        <div>
          <el-button
            v-if="param.is_editing"
            icon="el-icon-check"
            circle
            @click="submit()"
          ></el-button>
          <el-button
            v-else
            icon="el-icon-edit"
            circle
            @click="edit()"
          ></el-button>
          <el-button icon="el-icon-delete" circle @click="remove()"></el-button>
        </div>
      </el-col>
      <el-col :span="19">
        <el-form
          :rules="rules"
          :ref="'paramForm' + index"
          :model="param"
          v-if="param.is_editing"
          class="additional-param-form"
        >
          <el-row type="flex" :gutter="20">
            <el-col>
              <el-form-item prop="key" :label="__('Key')">
                <el-input v-model="param.key"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item prop="label" :label="__('Label')">
                <el-input v-model="param.label"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="path" :label="__('Path')">
            <el-input v-model="param.path"></el-input>
          </el-form-item>

          <template
            v-if="
              parameterType === 'request_param' ||
                parameterType === 'auth_param'
            "
          >
            <el-row type="flex" :gutter="20">
              <el-col>
                <el-form-item prop="type" :label="__('Get parameter from')">
                  <el-select
                    v-model="source"
                    style="width: 100%;"
                    filterable
                    default-first-option
                  >
                    <el-option
                      v-for="requestParameterOption in requestParameterOptions"
                      :label="requestParameterOption.label"
                      :value="requestParameterOption.value"
                      :key="
                        '_request_param_source_' + requestParameterOption.value
                      "
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col v-if="source === 'payment_card_expiry'">
                <el-form-item prop="form_type" :label="__('Form type')">
                  <el-input v-model="formType"></el-input>
                </el-form-item>
              </el-col>

              <el-col
                v-else-if="
                  requestParameterFormTypes && requestParameterFormTypes.length
                "
              >
                <el-form-item prop="form_type" :label="__('Form type')">
                  <el-select
                    v-model="formType"
                    style="width: 100%;"
                    filterable
                    default-first-option
                  >
                    <el-option
                      v-for="requestParameterFormType in requestParameterFormTypes"
                      :label="requestParameterFormType.label"
                      :value="requestParameterFormType.value"
                      :key="
                        '_request_param_form_type_' +
                          requestParameterFormType.value
                      "
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item
              prop="data"
              :label="__('Data')"
              v-if="source === 'payment_profile'"
            >
              <el-input v-model="param.data"></el-input>
            </el-form-item>

            <el-row type="flex" :gutter="20">
              <el-col>
                <el-form-item prop="default_data" :label="__('Default data')">
                  <el-input v-model="param.default_data"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item prop="test_data" :label="__('Test data')">
                  <el-input v-model="param.test_data"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>

          <template v-else-if="parameterType === 'response_param'">
            <el-form-item prop="default_data" :label="__('Default data')">
              <el-input v-model="param.default_data"></el-input>
            </el-form-item>
          </template>

          <el-table
            v-if="
              parameterType === 'request_param' ||
                parameterType === 'response_param'
            "
            class="list-table"
            fit
            style="width: 100%"
            :data="paymentProcessTypeArray"
            :empty-text="__('Please select a payment gateway')"
          >
            <el-table-column :label="__('Payment process type')">
              <template v-slot="scope">
                {{ __(scope.row.label) }}
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-radio-group
                  v-model="param.payment_process_types[scope.row.key]"
                  @change="setPaymentProcessType($event, scope.row.key)"
                >
                  <template v-if="parameterType === 'request_param'">
                    <el-radio label="required">{{ __("Required") }}</el-radio>
                    <el-radio label="optional">{{ __("Optional") }}</el-radio>
                  </template>
                  <el-radio
                    v-else-if="parameterType === 'response_param'"
                    label="in_use"
                    >{{ __("In use") }}</el-radio
                  >
                  <el-radio label="not_in_use">{{ __("Not in use") }}</el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <div v-else class="py-2">
          <template
            v-if="
              parameterType === 'request_param' ||
                parameterType === 'auth_param'
            "
          >
            <el-row :gutter="10">
              <el-col :span="12">
                <div class="param-display-label">{{ __("Key") }}</div>
                <div class="param-display-value">{{ param.key }}</div>
              </el-col>
              <el-col :span="12">
                <div class="param-display-label">{{ __("Label") }}</div>
                <div class="param-display-value">{{ param.label }}</div>
              </el-col>
            </el-row>
            <div>
              <div class="param-display-label">{{ __("Path") }}</div>
              <div class="param-display-value">{{ param.path }}</div>
            </div>
            <el-row :gutter="10">
              <el-col
                :span="12"
                v-if="param.default_value && param.default_value.length"
              >
                <div class="param-display-label">{{ __("Default value") }}</div>
                <div class="param-display-value">
                  {{ param.default_value }}
                </div>
              </el-col>
              <el-col
                :span="12"
                v-if="param.test_data && param.test_data.length"
              >
                <div class="param-display-label">{{ __("Test value") }}</div>
                <div class="param-display-value">{{ param.test_data }}</div>
              </el-col>
            </el-row>

            <template v-if="parameterType === 'request_param'">
              <div
                v-for="(paymentProcessType, key) in paymentProcessTypeArray"
                :key="'_payment_process_type_display_' + key"
              >
                <div class="param-display-label">
                  {{ paymentProcessType.label }}
                </div>
                <div
                  class="param-display-value"
                  v-if="
                    param.payment_process_types &&
                      param.payment_process_types.hasOwnProperty(
                        paymentProcessType.key
                      )
                  "
                >
                  {{ param.payment_process_types[paymentProcessType.key] }}
                </div>
              </div>
            </template>
          </template>

          <template v-else-if="parameterType === 'response_param'">
            <el-row :gutter="10">
              <el-col :span="12">
                <div class="param-display-label">{{ __("Key") }}</div>
                <div class="param-display-value">{{ param.key }}</div>
              </el-col>
              <el-col :span="12">
                <div class="param-display-label">{{ __("Label") }}</div>
                <div class="param-display-value">{{ param.label }}</div>
              </el-col>
            </el-row>
            <div>
              <div class="param-display-label">{{ __("Path") }}</div>
              <div class="param-display-value">{{ param.path }}</div>
            </div>
            <div v-if="param.default_value && param.default_value.length">
              <div class="param-display-label">{{ __("Default value") }}</div>
              <div class="param-display-value">
                {{ param.default_value }}
              </div>
            </div>
          </template>
        </div>
      </el-col>
    </el-row>
    <hr v-if="!isLast" style="margin-top: 20px;" />
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    parameterType: {
      type: String,
      default: "request_param"
    },
    parameter: {
      required: true
    },
    index: {
      required: true
    },

    paymentProcessTypes: {
      type: [Object, Array],
      default: () => null
    },

    isLast: {
      default: true,
      type: Boolean
    }
  },

  computed: {
    paymentProcessTypeArray() {
      if (this.paymentProcessTypes instanceof Array) {
        return this.paymentProcessTypes;
      } else {
        return Object.values(this.paymentProcessTypes);
      }
    },

    requestParameterFormTypes() {
      return _.get(
        _.find(this.requestParameterOptions, { value: this.source }),
        "children",
        []
      );
    }
  },

  data() {
    return {
      rules: {
        key: [
          { required: true, trigger: "blur", message: __("Key is required") }
        ]
      },
      source: "payment_profile",
      formType: "input",
      param: _.cloneDeep(this.parameter),
      requestParameterOptions: [
        {
          value: "payment_gateway",
          label: __("Payment gateway"),
          children: [
            {
              value: "input",
              label: __("Input")
            }
          ]
        },
        {
          value: "payment_profile",
          label: __("Payment profile"),
          children: [
            {
              value: "input",
              label: __("Input")
            },
            {
              value: "file_upload",
              label: __("File upload")
            }
          ]
        },
        {
          value: "payment_node",
          label: __("Payment node"),
          children: [
            {
              value: "variable",
              label: __("Variable")
            }
          ]
        },
        {
          value: "Payment Card Type",
          label: __("payment_card_type")
        },
        {
          value: "Payment Card CVC",
          label: __("payment_card_cvc")
        },
        {
          value: "Payment Card Number",
          label: __("payment_card_number")
        },
        {
          value: "Payment Card Expiry",
          label: __("payment_card_expiry"),
          children: [
            {
              value: "expiry_format",
              label: __("Expiry format")
            }
          ]
        }
      ]
    };
  },

  methods: {
    remove() {
      this.$emit("remove", this.index);
    },

    submit() {
      this.$refs["paramForm" + this.index].validate(valid => {
        if (valid) {
          this.param.type = this.source + ":" + this.formType;

          if (this.param.path == "") {
            this.param.path = this.param.key;
          }

          if (this.param.label == "") {
            this.param.label = this.param.key.replace(/ /g, "_");
          }

          for (const paymentProcessType of this.paymentProcessTypeArray) {
            if (!this.param.payment_process_types[paymentProcessType.key]) {
              this.$set(
                this.param.payment_process_types,
                paymentProcessType.key,
                "not_in_use"
              );
            }
          }

          this.$set(this.param, "is_editing", false);
          delete this.param.is_editing;
          this.$emit("update", this.index, this.param);
        }
      });
    },

    edit() {
      this.$set(this.param, "is_editing", true);
    },

    setPaymentProcessType(val, key) {
      if (
        this.param.payment_process_types instanceof Array &&
        !this.param.payment_process_types.length
      ) {
        this.$set(this.param, "payment_process_types", {});
      }

      this.$set(this.param.payment_process_types, key, val);
    }
  },

  created() {
    if (this.parameterType === "request_param") {
      const typeArray = this.parameter.type.split(":");
      if (typeArray) {
        this.source = typeArray[0];
        if (typeArray.length > 1) {
          this.formType = typeArray[1];
        } else {
          this.formType = "";
        }
      }
    }

    if (
      this.parameter.payment_process_types instanceof Array &&
      !this.parameter.payment_process_types.length
    ) {
      this.$set(this.param, "payment_process_types", {});
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/typography.scss";
@import "~@/styles/utils.scss";

::v-deep .additional-param-form {
  .el-form-item__label {
    font-weight: 100;
    font-size: 16px;
  }
}

.param-title {
  padding-bottom: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2rem;
  color: #a0a8b5;
}

.param-display-label {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  color: #a0a8b5;
  line-height: 1.2rem;
}
</style>
