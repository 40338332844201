<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapState } from "vuex";

export default {
  name: "PaymentProfilesManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("paymentprofiles", {
      contents: state => state.paymentProfiles
    })
  },

  methods: {
    ...mapActions("paymentgateways", {
      getPaymentGateways: "getPaymentGateways"
    }),

    initContent() {
      return {
        payment_profile_name: "",
        api_mode: "test",
        payment_gateway_id: null,
        json_payload: {
          payment_profile_params: {},
          additional_request_parameters: {},
          additional_response_parameters: {}
        },
        custom_payload: {}
      };
    }
  },

  mounted() {
    this.getPaymentGateways();
    this.contentType = "Payment Profile";
    this.primaryKey = "payment_profile_id";
    this.scope = "ac";
  }
};
</script>

<style lang="scss" scoped></style>
