<template>
  <div v-if="formAction" style="padding: 20px">
    <div>
      <div class="titles">{{ __("Name") }}</div>
      <div class="details">{{ contentForm.payment_profile_name }}</div>

      <el-button
        @click="openModal = true"
        class="editBtn"
        :disabled="!can('manage.payment-profiles.write')"
        >{{ __("Edit") }}</el-button
      >
      <el-button
        @click="
          handleDelete(
            contentForm,
            'PaymentProfile',
            contentForm.payment_profile_id
          )
        "
        class="deleteBtn"
        :disabled="!can('manage.payment-profiles.write')"
      >
        {{ __("Delete") }}
      </el-button>

      <delete-item></delete-item>
    </div>

    <el-dialog
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
    >
      <div class="payment-profile-form-dialog">
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <page-header :title="__('Payment Profile')" :contentId="id" />
          </el-col>
        </el-row>
        <el-scrollbar :native="false">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="payment_profile_name" :label="__('Name')">
                  <el-input
                    v-model="contentForm.payment_profile_name"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="api_mode" :label="__('API mode')">
                  <el-radio-group v-model="contentForm.api_mode">
                    <el-radio :label="__('test')" value="test">{{
                      __("Test")
                    }}</el-radio>
                    <el-radio :label="__('live')" value="live">{{
                      __("Live")
                    }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  prop="payment_gateway_id"
                  :label="__('Payment Gateway')"
                >
                  <el-select
                    filterable
                    v-model="contentForm.payment_gateway_id"
                    @change="selectPaymentGateway"
                    style="width: 100%;"
                    default-first-option
                  >
                    <el-option
                      v-for="paymentGateway in getPaymentGatewaysSortedByName"
                      :key="paymentGateway.payment_gateway_name"
                      :value="paymentGateway.payment_gateway_id"
                      :label="paymentGateway.payment_gateway_name"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row
              type="flex"
              v-for="(param, key) in paymentProfileParams"
              :key="'_payment_profile_parameter_' + key"
            >
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="param.label"
                  :prop="'json_payload.payment_profile_params.' + param.key"
                >
                  <el-input
                    v-if="param.form_type === 'input'"
                    v-model="
                      contentForm.json_payload.payment_profile_params[param.key]
                    "
                  ></el-input>
                  <file-uploader-minimal
                    v-else-if="param.form_type === 'file_upload'"
                    :file-url="
                      contentForm.json_payload.payment_profile_params[
                        param.key
                      ] &&
                      contentForm.json_payload.payment_profile_params[param.key]
                        .file_url
                        ? contentForm.json_payload.payment_profile_params[
                            param.key
                          ].file_url
                        : ''
                    "
                    :file-name="
                      contentForm.json_payload.payment_profile_params[
                        param.key
                      ] &&
                      contentForm.json_payload.payment_profile_params[param.key]
                        .file_name
                        ? contentForm.json_payload.payment_profile_params[
                            param.key
                          ].file_name
                        : ''
                    "
                    :allow-types="[param.file_upload]"
                    folder="certs"
                    @on-success="handleUploadSuccess($event, param.key)"
                    @on-delete="handleFileDelete($event, param.key)"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6" :key="componentKey">
                <div class="flex justify-between">
                  <div class="form-label">
                    {{ __("Additional request parameters") }}
                  </div>
                  <div>
                    <el-button
                      icon="el-icon-plus"
                      circle
                      @click="addRequestParameter()"
                    ></el-button>
                  </div>
                </div>
                <custom-payload-param
                  :ref="'additionalRequestParam' + index + 'Ref'"
                  v-for="(param, index) in additionalRequestParams"
                  :payment-process-types="paymentProcessTypes"
                  parameter-type="request_param"
                  :parameter="param"
                  :index="index"
                  :key="'request_param' + index"
                  :is-last="index === additionalRequestParams.length - 1"
                  :is-editing="
                    param.is_editing && param.is_editing === true ? true : false
                  "
                  @remove="removeRequestParameter(index)"
                  @update="updateRequestParameter"
                ></custom-payload-param>
              </el-col>
            </el-row>

            <el-row type="flex" style="padding-top: 20px;">
              <el-col :span="12" :offset="6" :key="componentKey">
                <div class="flex justify-between">
                  <div class="form-label">
                    {{ __("Additional response parameters") }}
                  </div>
                  <div>
                    <el-button
                      icon="el-icon-plus"
                      circle
                      @click="addResponseParameter()"
                    ></el-button>
                  </div>
                </div>
                <custom-payload-param
                  :ref="'additionalResponseParam' + index + 'Ref'"
                  v-for="(param, index) in additionalResponseParams"
                  :payment-process-types="paymentProcessTypes"
                  parameter-type="response_param"
                  :parameter="param"
                  :index="index"
                  :key="'response_param' + index"
                  :is-last="index === additionalResponseParams.length - 1"
                  :is-editing="
                    param.is_editing && param.is_editing === true ? true : false
                  "
                  @remove="removeResponseParameter(index)"
                  @update="updateResponseParameter"
                ></custom-payload-param>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item>
                  <save-button
                    type="primary"
                    @click="submitForm"
                    class="submitBtn"
                    :disabled="
                      (!contentUpdated && additionalParamsNotChanged) ||
                        !can('manage.payment-profiles.write')
                    "
                    :primaryKey="id"
                  />
                  <el-button @click="handleCancel" class="cancelBtn">{{
                    __("Cancel")
                  }}</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import { mapActions, mapGetters, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import CustomPayloadParam from "@/views/manage/payment/components/customPayloadParam";
import FileUploaderMinimal from "@/components/uploaders/types/FileUploaderMinimal";
import _ from "lodash";
import DeleteItem from "@/components/DeleteItem";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    CustomPayloadParam,
    FileUploaderMinimal,
    DeleteItem,
    SaveButton
  },

  data() {
    return {
      rules: {
        payment_profile_name: [
          {
            required: true,
            message: __("Payment profile name cannot be empty"),
            trigger: "blur"
          }
        ],
        payment_gateway_id: [
          {
            required: true,
            message: __("Please select a payment gateway"),
            trigger: "change"
          }
        ],
        api_mode: [
          {
            type: "string",
            required: true,
            message: __("Please select an api mode"),
            trigger: "change"
          }
        ]
      },
      isSubmitting: false,
      customPayload: null,

      //new
      paymentProcessTypes: [],
      paymentProfileParams: [],
      additionalRequestParams: [],
      additionalResponseParams: [],
      selectedPaymentGateway: {},
      fileList: [],
      parametersValid: false,
      componentKey: 0,
      // check in content delete
      forceDeleteEventName: "initiate-force-delete-PaymentProfile"
    };
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapState("paymentgateways", {
      loading: state => state.loading
    }),

    ...mapGetters("paymentgateways", {
      getPaymentGateway: "getPaymentGateway",
      getPaymentGatewaysSortedByName: "getPaymentGatewaysSortedByName"
    }),

    additionalParamsNotChanged() {
      return (
        _.isEqual(
          this.additionalRequestParams,
          Object.values(
            this.contentForm.json_payload.additional_request_parameters
          )
        ) &&
        _.isEqual(
          this.additionalResponseParams,
          Object.values(
            this.contentForm.json_payload.additional_response_parameters
          )
        )
      );
    }
  },

  methods: {
    ...mapActions("paymentprofiles", {
      createPaymentProfile: "createPaymentProfile",
      updatePaymentProfile: "updatePaymentProfile",
      deleteContentMethod: "deletePaymentProfile",
      undoDeleteContent: "undoDeletePaymentProfile"
    }),

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        this.parametersValid = true;

        this.$set(
          this.contentForm.json_payload,
          "additional_request_parameters",
          {}
        );
        for (let i in this.additionalRequestParams) {
          if (
            this.additionalRequestParams[i].is_editing &&
            !this.additionalRequestParams[i].key
          ) {
            this.parametersValid = false;
            this.$refs["additionalRequestParam" + i + "Ref"][0].submit();
          } else {
            this.$set(
              this.contentForm.json_payload.additional_request_parameters,
              this.additionalRequestParams[i].key,
              this.additionalRequestParams[i]
            );
          }
        }

        this.$set(
          this.contentForm.json_payload,
          "additional_response_parameters",
          {}
        );
        for (let i in this.additionalResponseParams) {
          if (
            this.additionalResponseParams[i].is_editing &&
            !this.additionalResponseParams[i].key
          ) {
            this.parametersValid = false;
            this.$refs["additionalResponseParam" + i + "Ref"][0].submit();
          } else {
            this.$set(
              this.contentForm.json_payload.additional_response_parameters,
              this.additionalResponseParams[i].key,
              this.additionalResponseParams[i]
            );
          }
        }

        if (valid && this.parametersValid) {
          this.isSubmitting = true;
          const process =
            this.id === -1
              ? this.createPaymentProfile
              : this.updatePaymentProfile;

          this.contentForm.ac_id = this.selectedAccountId;
          // this.contentForm.json_payload = { ...this.paymentProfileJson };
          this.contentForm.custom_payload = { ...this.customPayload };

          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Payment Profile added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Payment Profile updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    selectPaymentGateway(paymentGatewayId) {
      if (paymentGatewayId) {
        this.selectedPaymentGateway = _.cloneDeep(
          this.getPaymentGateway(paymentGatewayId)
        );
        if (
          this.selectedPaymentGateway &&
          this.selectedPaymentGateway.json_data
        ) {
          // this.$set(this.contentForm, "json_payload", {
          //   ...this.selectedPaymentGateway.json_data
          // });
          this.loadParameters();
        }
      }
    },

    loadParameters() {
      this.resetForm();
      if (this.selectedPaymentGateway.json_data.payment_process_types) {
        for (let i in this.selectedPaymentGateway.json_data
          .payment_process_types) {
          const paymentProcessType = {
            ...this.selectedPaymentGateway.json_data.payment_process_types[i]
          };
          this.paymentProcessTypes.push(paymentProcessType);
        }
      }

      if (this.selectedPaymentGateway.json_data.request_parameters) {
        for (let i in this.selectedPaymentGateway.json_data
          .request_parameters) {
          const paramType = this.selectedPaymentGateway.json_data
            .request_parameters[i].type;
          if (paramType.startsWith("payment_profile:")) {
            let param = {
              ...this.selectedPaymentGateway.json_data.request_parameters[i]
            };
            param["form_type"] = paramType.split("payment_profile:").pop();
            this.paymentProfileParams.push(param);

            if (param.required == true) {
              this.$set(
                this.rules,
                "json_payload.payment_profile_params." + param.key,
                {
                  required: true,
                  message: __(":param cannot be empty", { param: param.label }),
                  trigger: "blur"
                }
              );
            }
          }
        }
      }
    },

    loadAdditionalParameters() {
      this.additionalRequestParams = Object.values(
        this.contentForm.json_payload.additional_request_parameters
      );
      this.additionalResponseParams = Object.values(
        this.contentForm.json_payload.additional_response_parameters
      );
    },

    resetForm() {
      this.paymentProcessTypes.splice(0, this.paymentProcessTypes.length);
      this.paymentProfileParams.splice(0, this.paymentProfileParams.length);
      this.additionalResponseParams.splice(
        0,
        this.additionalResponseParams.length
      );
      this.additionalRequestParams.splice(
        0,
        this.additionalRequestParams.length
      );
    },

    addRequestParameter() {
      this.additionalRequestParams.push({
        key: "",
        label: "",
        required: false,
        type: "payment_node:variable",
        default_data: "",
        test_data: "",
        data: "",
        path: "",
        payment_process_types: {},
        is_editing: true
      });
    },

    updateRequestParameter(index, param) {
      this.$set(this.additionalRequestParams, index, param);
    },

    removeRequestParameter(index) {
      this.additionalRequestParams.splice(index, 1);
      this.componentKey++;
    },

    addResponseParameter() {
      this.additionalResponseParams.push({
        key: "",
        label: "",
        required: false,
        type: "response",
        default_data: "",
        path: "",
        payment_process_types: {},
        is_editing: true
      });
    },

    updateResponseParameter(index, param) {
      this.$set(this.additionalResponseParams, index, param);
    },

    removeResponseParameter(index) {
      this.additionalResponseParams.splice(index, 1);
      this.componentKey++;
    },

    initializePaymentProfile() {
      this.isSubmitting = true;
      if (this.id !== -1) {
        if (this.contentForm.payment_gateway_id) {
          this.selectedPaymentGateway = _.cloneDeep(
            this.getPaymentGateway(this.contentForm.payment_gateway_id)
          );
          this.loadParameters();
          this.loadAdditionalParameters();
        }
      } else {
        this.$set(this.contentForm, "json_payload", {});
        this.$set(this.contentForm.json_payload, "payment_profile_params", {});
        this.$set(
          this.contentForm.json_payload,
          "additional_request_parameters",
          {}
        );
        this.$set(
          this.contentForm.json_payload,
          "additional_response_parameters",
          {}
        );
        this.resetForm();
      }
      this.isSubmitting = false;
    },

    // file upload
    setFileInfo(file) {
      console.log(file);
    },

    handleUploadSuccess(file, key) {
      this.$set(this.contentForm.json_payload.payment_profile_params, key, {
        file_name: file.uploaded_file_name,
        file_url: file.url
      });
    },

    handleFileDelete(file, key) {
      this.$set(this.contentForm.json_payload.payment_profile_params, key, {
        file_url: "",
        file_name: ""
      });
    }
  },

  watch: {
    "content.custom_payload": function(val, oldVal) {
      if (val !== oldVal) {
        if (val && val.length) {
          this.customPayload = JSON.parse(val);
        }
      }
    },

    customPayload: {
      deep: true,
      handler(val) {
        console.log(val);
        this.$emit("change", _.cloneDeep(val));
      }
    },

    openModal(val) {
      if (val === true) {
        this.initializePaymentProfile();
      }
    }
  },

  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });
  },

  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/typography.scss";
@import "~@/styles/utils.scss";
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/node_common.scss";
@import "~@/styles/button.scss";

.titles {
  color: #6e7681;
  font-weight: bold;
  margin-top: 10px;
}

.details {
  color: #a0a8b5;
  font-size: 0.85em;
  margin-top: 5px;
}

.cancelBtn {
  border: none;
  height: 44px;
  &:hover {
    background: lightgrey;
    color: white;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}

.form-label {
  font-weight: 400;
  line-height: 1.2rem;
  padding: 0 0 10px 0;
  font-size: 1.2rem;
  color: #454545;
}
</style>
